import {images} from "../assets/img";

export const INFORMATIONS = {
  name:"Xin Chao",
  ggMapUrl: "https://maps.app.goo.gl/tySXwxYwVLCuXLHH9",
  address: "42 Terminus Rd, Eastbourne BN21 3LP, United Kingdom",
  email: "xinchaouk2024@gmail.com",
  tel: "+447448150081",
  phone: "+44 7448 150081",
  facebook: "https://www.facebook.com/XinChaoUK",
  instagram: "https://www.instagram.com/xinchao.eastbourne",
};

export const SERVICES = [
  {
    name:"Manicures",
    description:"We specialize in providing clients with professional nail treatments using only the finest brands and high-quality products. With over 600 colors available and special attention paid to your skin and cuticles, our treatments will leave your nails looking fantastic.",

    image: images.nailService
  },
  {
    name:"Pedicures",
    description:"Nails And Toe Are Shaped, Smoothed And Cuticle Care, Exfoliation Cucumber/Peppermint Mask, Cream And Finish With Colour Of Your Choice.",
    image: images.nailService2
  },
  {
    name:"Our Technicians",
    description:"Our highly qualified team of nail technicians will take care your nails the ins and outs during your experiences at our nails bar in Forfar. We always welcome you with a warm smile and make you feel at home.",
    image: images.nailService3
  },
  {
    name:"Nails Art",
    description:"From classic to outstanding, our talented technicians can advise you the bespoke nail art of your imagine. Come here and enjoy your amazing nails art at our local shop in Anniesland, Glasgow",
    image: images.nailService4
  }
]

export const LANGUAGE_ID = {
  vi:'vi',
  en:'en'
}
