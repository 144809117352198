import classes from "./Introduction02.module.css";
import React, { useContext, useEffect, useState } from "react";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";

const Introduction02 = (props) => {
  const { introduction02Ref } = props || {};
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [imagesIntro, setImages] = useState([
    images.nailImg03,
    images.nailImg04,
    images.nailImg05,
  ]);
  const [texts, setTexts] = useState([
    {
      title: "Discover Your Favourite Restaurant In Eastbourne",
      description:
        "Experience the essence of Vietnamese cuisine at Xin Chao, your premier destination for authentic Vietnamese flavors in the heart of the UK. We prioritize culinary excellence, meticulous attention to detail, and exceptional customer service to ensure every visit leaves you feeling satisfied and delighted. Join us at our inviting restaurant and indulge in a delightful culinary journey that will transport you to the vibrant streets of Vietnam. Book your table today and savor the flavors of our exquisite dishes.",
    },
    {
      title: "Our Restaurant Guarantee",
      description:
        "At Xin Chao, we're dedicated to ensuring your dining experience is nothing short of exceptional. Our commitment to culinary excellence and unparalleled customer service shines through in every dish we serve. From our meticulously crafted recipes to our warm and inviting atmosphere, we strive to create memorable moments for every guest. If you have any questions or concerns during your visit, our attentive staff is always ready to assist you. Join us for a culinary journey that promises to delight your senses and leave you craving more.",
    },
  ]);

  // useEffect(() => {
  //   const introduction_image = RemoteConfig.getConfigValue(
  //     "introduction2_images"
  //   );
  //   const introduction2_texts = RemoteConfig.getConfigValue(
  //     "introduction2_texts"
  //   );
  //
  //   introduction_image &&
  //     Array.isArray(introduction_image) &&
  //     introduction_image.length > 0 &&
  //     setImages(introduction_image);
  //
  //   introduction2_texts && setTexts(introduction2_texts);
  // }, [isFetchActivateRemoteConfig]);

  return (
    <div ref={introduction02Ref} className={classes.introduction}>
      <div className={classes.rowWrapper}>
        <div className={classes.introTextContainer}>
          {texts?.map((item, idx) => {
            return (
              <div>
                <h2>{item?.title}</h2>
                <p>{item?.description}</p>
              </div>
            );
          })}

          <BookNowBtn />
        </div>

        <div className={classes.imageRow}>
          <img
            className={classes.introImg}
            alt={"Primary Banner"}
            src={images.horizontalBanner02}
          />
          <img
            className={classes.introSubDrinkImg}
            alt={"Drink"}
            src={images.lemongrassDrink}
          />
          <img
            className={classes.introSubImg}
            alt={"Food"}
            src={images.bunchaHanoi}
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction02;
