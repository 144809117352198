import classes from "./Introduction.module.css";
import React,{useEffect,useContext,useState} from "react";
import {images} from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import {INFORMATIONS} from '../../utils/Const'

//ABOUT US
const Introduction = (props) => {
    const {introductionRef} = props || {};
    const { isFetchActivateRemoteConfig } = useContext(AuthContext);
    const [image, setImage] = useState(images.nailImg02);

    // useEffect(() => {
    //   const introduction_image = RemoteConfig.getConfigValue("introduction_image");
    //   introduction_image && setImage(introduction_image);
    // }, [isFetchActivateRemoteConfig]);

    return (
        <div ref={introductionRef} className={classes.introduction}>
                

            <div className={classes.introHeader}>
                {/* <h2 className={classes.cursiveText}>{INFORMATIONS.name}</h2> */}
                <h2 className={classes.cursiveText}>{'The Finest\nVietnamese Street Food'}</h2>
                <h3 className={classes.introductionText}>Indulge in <h2 className={classes.cursiveName}>{` ${INFORMATIONS.name} `}</h2> authentic Vietnamese cuisine, offering delectable dishes bursting with flavor.</h3>
            </div>
            <div className={classes.rowWrapper}>
            <img alt={'Intro'} src={ images.verticalBanner01 } className={classes.introImg}/>
            <img alt={'Intro'} src={ images.verticalBanner02 } className={classes.introImg}/>
            <div className={classes.introTextContainer}>
                <div>
                    <h3 className={classes.greyIntroTextContainer}>Best restaurant in Eastbourne</h3>
                </div>
                <p className={classes.greyIntroTextContainer}>Welcome to Xin Chao, your new go-to spot for authentic Vietnamese cuisine in the UK! Discover delicious dishes that capture the essence of Vietnam's culinary delights. We can't wait to serve you!</p>
                <p className={classes.greyIntroTextContainer}>Step into the vibrant flavors of Vietnam at Xin Chao, your newest destination for mouthwatering Vietnamese cuisine in the heart of the UK. Embark on a culinary journey filled with traditional dishes and bold flavors that will tantalize your taste buds. We're excited to share our passion for Vietnamese food with you!</p>
                <BookNowBtn/>
            </div>
            </div>
        </div>
    );
};

export default Introduction;
