export const images = {
    flagUk: require('./flag-uk.png'),
    flagVn: require('./flag-vn.png'),
    fbIcon: require('./fb-icon.png'),
    instaIcon: require('./insta-icon.png'),
    leftArrow: require('./left-arrow.png'),
    menuBanhmi: require('./menu-banhmi.png'),
    menuDrinks: require('./menu-drinks.jpg'),
    menuFood: require('./menu-food.jpg'),
    grandOpening01: require('./grand-opening01.png'),
    grandOpening02: require('./grand-opening02.png'),
    xinchaoLogo: require('./xinchao-logo.png'),
    pho: require('./pho.png'),
    goicuon: require('./goi-cuon.png'),
    beefNoodle: require('./Dish_Beef_Noodle_Soup-removebg-preview.png'),
    bunchaHanoi: require('./Dish_Bun_Cha_Hanoi_Fish_Sauce-removebg-preview.png'),
    springroll: require('./Dish_Fresh_Spring_Roll-removebg-preview.png'),
    roastedChicken: require('./Dish_Roasted_Skewer_Chicken_Rice_Bowl-removebg-preview.png'),
    lemongrassDrink: require('./Dish_Lemongrass_Orange_Peach_Tea-removebg-preview.png'),
    horizontalBanner01:  require('./Hero_1.jpg'),
    horizontalBanner02:  require('./Hero_2.jpg'),
    verticalBanner01:  require('./VertialBanner01.png'),
    verticalBanner02:  require('./VertialBanner02.png'),
};
