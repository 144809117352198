import classes from "./PrimaryBanner.module.css";
import React, { useContext, useEffect, useState, memo } from "react";
import AuthContext from "../../store/auth-context";
import { t } from "../../locales/i18n";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";

const PrimaryBanner = (props) => {
  const {} = props || {};
  // const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  // const [banner, setBanner] = useState(images.castleNailBanner);

  // useEffect(() => {
  //   const primary_banner = RemoteConfig.getConfigValue("primary_banner");
  //   primary_banner && setBanner(primary_banner);
  // }, [isFetchActivateRemoteConfig]);

  return (
    <div className={classes.primaryBanner}>
      <div className={classes.textContainer}>
        <h2>Excellence dining experience</h2>
        <h3>
          Join us at the table as you dine for the perfect meal. We treat all of
          our customers with utmost care and service.
        </h3>
        <h3>Have a toast</h3>
        {/*<BookNowBtn />*/}
      </div>

      <div className={classes.primaryBannerContainer}>
        <img
          className={classes.primaryBannerImage}
          alt={"Primary Banner"}
          loading="eager"
          src={images.horizontalBanner01}
        />
        <img className={classes.pho} alt={"Pho"} src={images.beefNoodle} />
        <img
          className={classes.goicuon}
          alt={"Goi cuon"}
          src={images.springroll}
        />
      </div>
    </div>
  );
};

export default memo(PrimaryBanner);
