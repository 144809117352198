import classes from "./FooterInfo.module.css";
import { INFORMATIONS } from "../../utils/Const";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

const FooterInfo = (props) => {
  const isMobile = useCheckIsMobile();

  return (
    <div className={classes.container}>
      <div className={classes.columnInfo}>
        <div className={classes.address}>
          <h2 className={classes.restaurantName}>{INFORMATIONS.name}</h2>
          <a
            target={"_blank"}
            href={INFORMATIONS.ggMapUrl}
            rel="noreferrer"
          >
            {`42 Terminus Rd, Eastbourne BN21 3LP,${isMobile ? "" : "\n"}United Kingdom\n\n`}
          </a>
        </div>

        <div className={classes.openingTimeWrapper}>
          <h3>Opening Time</h3>
          <div className={classes.openingTimeContainer}>
            <div className={classes.openingTime}>
              <h3>{"Monday"}</h3>
              <h3>{"Closed"}</h3>
            </div>
            <div className={classes.openingTime}>
              <h3>{"Tuesday - Sunday"}</h3>
              <h3>{"11:00 AM - 07:00 PM"}</h3>
            </div>
          </div>
        </div>

      </div>

      <div className={classes.contactContainer}>
        <h3>Contact Us</h3>
        <a
          href={`tel:${INFORMATIONS.tel}`}
        >{`Hotline: ${INFORMATIONS.phone}\n\n`}</a>
        <a
          href={`mailto:${INFORMATIONS.email}`}
        >{`Email: ${INFORMATIONS.email}`}</a>
      </div>

      <div className={classes.columnInfo}>
        <div className={`${classes.contactContainer} ${classes.marginBottom}`}>
          <h3>Follow Us</h3>
          <a
            target={"_blank"}
            href={INFORMATIONS.instagram}
            rel="noreferrer"
          >
            <img alt={"insta"} src={images.instaIcon} />
          </a>
          <a
            target={"_blank"}
            href={INFORMATIONS.facebook}
            rel="noreferrer"
          >
            <img alt={"insta"} src={images.fbIcon} />
          </a>
        </div>

        <br />



        <div
          className="fb-page"
          data-href={INFORMATIONS.facebook}
          data-tabs="timeline"
          data-width={isMobile ? "320" : "300"}
          data-height="100"
          data-small-header="false"
          data-adapt-container-width="false"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite={INFORMATIONS.facebook}
            className="fb-xfbml-parse-ignore"
          >
            <a href={INFORMATIONS.facebook}>{INFORMATIONS.name}</a>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
