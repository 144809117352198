import React from "react";
import classes from "./Contacts.module.css";
import {INFORMATIONS} from "../../utils/Const";
import {images} from "../../assets/img";
import BookNowBtn from "../BookNowBtn";

const Contacts = (props) => {
    return (
        <div ref={props?.contactsRef} className={classes.contacts}>
            <div className={classes.contactsContainer}>
                <h1>{"Contact Us"}</h1>

                <a href={`tel:${INFORMATIONS.tel}`}>{`Hotline: ${INFORMATIONS.phone}\n\n`}</a>
                <a href={`mailto:${INFORMATIONS.email}`}>{`Email: ${INFORMATIONS.email}`}</a>

                <div>
                    <a
                        target={"_blank"}
                        href={INFORMATIONS.instagram}
                        rel="noreferrer">
                        <img
                            className={classes.contactsContainerImg}
                            alt={"insta"}
                            src={images.instaIcon}
                        />
                    </a>
                    <a target={"_blank"} href={INFORMATIONS.facebook} rel="noreferrer">
                        <img className={classes.contactsContainerImg} alt={"facebook"} src={images.fbIcon}/>
                    </a>
                </div>
                <BookNowBtn isShowArrow={false} style={classes.bookNowBtn}/>
            </div>

            <iframe
                title="google-map"
                className={classes.iframe}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2523.3572207007915!2d0.28109189999999995!3d50.7689513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df731742a66343%3A0x9c84d41383c6a589!2sXin%20Chao%20Vietnamese%20Street%20Food!5e0!3m2!1sen!2s!4v1711904262145!5m2!1sen!2s"                allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
    );
};

export default Contacts
